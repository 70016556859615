.button {
  padding: 10px;
  border: 1px solid #4d9636;
  color: #ffffff;
  background: #4d9636;
  border-radius: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 25px 50px -12px #4d9636;
  width: 100%;
}

.button:hover {
  filter: drop-shadow(0 0 0.3rem #4d9636);
}

.outline_button {
  padding: 10px;
  border: 1px solid #4d9636;
  color: #4d9636;
  border-radius: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
}

.outline_button:hover {
  background-color: rgba(77, 150, 54, 0.1);
  transition: background-color 0.3s ease-in-out;
}

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.full-width-wrapper {
  width: 100%;
  height: 113vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.content-container {
  max-width: 1400px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.full-width-wrapper::before {
  margin-top: -30px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/background/hero_vectors.svg');
  background-size: cover;
  z-index: -1;
}

.hero_container {
  border-radius: 20px;
  border: 1px solid #3dff00;
  box-shadow: 0px 10px 60px 0px #00000014;
  backdrop-filter: blur(10px);
  padding: 40px;
  @media (max-width: 768px) {
    padding: 10px;
  }
}

.hero_pepe:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.choose_us-wrapper {
  width: 100%;
  height: 153vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.choose_us-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/background/choose_us_lines.svg');
  background-size: cover;
  z-index: -1;
}

.tokenomics-wrapper {
  width: 100%;
  height: 135vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.tokenomics-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/background/tokenomics.svg');
  background-size: cover;
  z-index: -1;
}

.tokenomics_grid {
  background: linear-gradient(
    120.19deg,
    rgba(77, 150, 54, 0.08) 0%,
    rgba(77, 150, 54, 0.01) 100%
  );
}

@media (max-width: 1400px) {
  .full-width-wrapper {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .full-width-wrapper::before {
    background-image: url('./assets/background/hero_vectors_mobile.svg');
    opacity: 0.3;
  }

  .choose_us-wrapper {
    height: 100%;
  }

  .tokenomics-wrapper {
    height: 100%;
  }
}
